import type { FC } from 'react';
import styles from './index.module.scss';
import Image from 'next/image';
import { track } from '@/utils/track';
interface ToolCardProps {
  [key: string]: any;
  url: string;
  title: string;
  onClick?: () => void;
}
const ToolCard: FC<ToolCardProps> = ({ title, url, ...otherProps }) => {
  return (
    <div
      className={styles.card}
      {...otherProps}
      onClick={() => {
        track({
          name: '按钮点击',
          keyword: `导航栏【文档与支持】`,
          product_type: title,
        });
        otherProps.onClick?.();
      }}
    >
      <Image src={url} alt="" width={40} height={40} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};
export default ToolCard;
