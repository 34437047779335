import { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import Image from 'next/image';
import classNames from 'classnames';
import { useThrottleFn } from 'ahooks';
import { userModelContainer } from '@/store/userModel';
import pinyinSearch from '@/components/common/PinyinSearch';
import { dashboardSitePrefixPath, getToken } from '@/utils';
import demo from '../CreateRobotModal/demo.gif';
import styles from './index.module.less';
import { track } from '@/utils/track';

interface ServiceSelectProps {
  serviceList: Record<string, any>[];
  deps?: any;
  afterClose?: () => void;
  className?: string;
  onFocus?: () => void;
}

function ServiceSelect({ serviceList, deps, afterClose, className, onFocus }: ServiceSelectProps) {
  const [value, setValue] = useState('');
  const [showCreateRobotModal, setShowCreateRobotModal] = useState(false);
  const [options, setOptions] = useState(serviceList);

  const { run: changeHandle } = useThrottleFn(
    (value) => {
      const result = pinyinSearch(
        serviceList,
        ['app_name', 'description', 'search_keywords'],
        value,
      );
      setOptions(result);
    },
    { wait: 300 },
  );

  const { userInfo } = userModelContainer.useContainer();

  useEffect(() => {
    setValue('');
  }, [deps]);

  const onChange = (value) => {
    setValue(value);
    changeHandle(value);
    if (value) {
      track({ name: '搜索记录', keyword: '体验中心搜索', search: value });
    }
  };

  const onSelect = (value) => {
    const selectRow = serviceList.find((pro) => pro.id === value);
    if (selectRow && selectRow.interaction !== -1 && userInfo?.account) {
      window.open(
        `${dashboardSitePrefixPath}${selectRow.interaction_uri}?service=${selectRow.service}`,
      );
    } else if (selectRow?.service) {
      window.open(`/experience/${selectRow.service}`);
    }
  };

  const closeHandle = () => {
    setValue('');
    setShowCreateRobotModal(false);
    if (afterClose) {
      afterClose();
    }
  };

  return (
    <>
      <div className={classNames(styles.toolsTop, className)}>
        <img className={styles.searchImg} src={'/images/experience/search.svg'} alt="" />
        <Select
          key={deps}
          className={!value ? styles.select : styles.colorSelect}
          showSearch
          allowClear
          clearIcon={
            <Image
              alt=""
              src={'/images/experience/icon-input-del.svg'}
              width={16}
              height={16}
              layout="fixed"
            />
          }
          placeholder="搜索产品"
          defaultActiveFirstOption={false}
          showArrow={false}
          value={undefined}
          filterOption={false}
          getPopupContainer={(node) => node.parentNode}
          onFocus={onFocus}
          onSelect={onSelect}
          onSearch={onChange}
          onClear={() => {
            setValue('');
          }}
          notFoundContent={
            value && (
              <div>
                未找到相关产品，试试
                <a
                  className={styles.selectA}
                  onClick={() => {
                    setShowCreateRobotModal(true);
                  }}
                  style={{ color: '#1A66FF' }}
                >
                  自建机器人
                </a>
              </div>
            )
          }
          dropdownStyle={{ zIndex: 100 }}
        >
          {value &&
            options?.length &&
            options.map((d: any) => (
              <Select.Option key={d.id} value={d.id}>
                <span
                  className={styles.selectA}
                  style={{ color: '#030A1A', display: 'block', width: '100%' }}
                >
                  {d.match?.app_name || d.app_name}
                </span>
              </Select.Option>
            ))}
        </Select>
        <div className={styles.test} />
      </div>

      <Modal
        destroyOnClose
        visible={showCreateRobotModal}
        footer={null}
        centered
        width="780px"
        className={styles.createRobotModal}
        onCancel={(e) => {
          e.stopPropagation();
          closeHandle();
        }}
      >
        <div className="title">什么是自建机器人？</div>
        <div className="desc">
          您可以利用简单的配置工具，创建属于自己的文字识别机器人，提取任意自定义样式的文本。
        </div>
        <div className="imgWrapper">
          <Image alt="" src={demo} width={600} height={375} />
        </div>
        <div className="btnWrapper">
          <div
            className="btn"
            onClick={(e) => {
              e.stopPropagation();
              closeHandle();
              const token = getToken();
              if (token) {
                window.open(`${dashboardSitePrefixPath}/dashboard/robotlist`, '_blank');
              } else {
                window.open(
                  `/user/login?redirect=${encodeURIComponent(
                    `${dashboardSitePrefixPath}/dashboard/robotlist`,
                  )}`,
                  '_self',
                );
              }
            }}
          >
            立即体验
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ServiceSelect;
