import React, { useEffect, useState, useRef } from 'react';
import { ssoUrl, getEnv, dashboardSitePrefixPath, formatMoney } from '@/utils/index';
import { Popover, message, Button, Menu } from 'antd';
import styles from './Index.module.scss';
import Image from '@/components/Image';

import { getBalance, logout } from 'service/common/Index';
import { userModelContainer } from '@/store/userModel';
import { track } from '@/utils/track';

const userCenterMenu = [
  {
    name: '账号与开发者信息',
    path: `${dashboardSitePrefixPath}/dashboard/setting`,
    activeArr: [`${dashboardSitePrefixPath}/userCenter/set_password`],
    key: '4-1',
    logKey: 'account_settings',
    icon: '/icons/users.svg',
  },

  {
    name: '充值消费',
    path: `${dashboardSitePrefixPath}/dashboard/userCenter/finance`,
    activeArr: [`${dashboardSitePrefixPath}/dashboard/userCenter/charge`],
    key: '4-2',
    logKey: 'financial_center',
    icon: '/icons/wallet.svg',
  },
];

const PoverContent = ({ visible }: { visible?: boolean }) => {
  const [balance, setBalance] = useState(0);
  const { setBalanceData, clearUserInfo, clearContract } = userModelContainer.useContainer();

  const menuContent = (
    <Menu>
      {userCenterMenu?.map((menuItem) => {
        return (
          <Menu.Item
            className="info-item"
            icon={<Image alt="" width={20} height={20} src={menuItem.icon} />}
            key={menuItem.key}
            onClick={() => {
              track({ name: '按钮点击', keyword: `个人信息悬浮【${menuItem.name}】` });
              window.open(menuItem.path);
            }}
          >
            {menuItem.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  useEffect(() => {
    (async () => {
      if (!visible) return;
      const data: any = await getBalance();
      if (data.code === 200) {
        setBalance(data.data.available_balance);
        setBalanceData(data.data);
      }
    })();
  }, [visible]);
  /**
   *
   * @description 首页退出登录，停留在当前页面
   */
  const onLogout = async () => {
    const { msg, code, data } = await logout();
    if (code === 200) {
      clearUserInfo();
      clearContract();
      if (data && data.is_sso === 1) {
        const redirectUrl = encodeURI(
          `${window.location.protocol}//${window.location.host}/user/ssoAuth`,
        );
        window.location.replace(
          `${ssoUrl[getEnv()]}?isLoginOut=1&platform_id=${
            data.platform_id
          }&redirect=${redirectUrl}`,
        );
        return;
      }
      // window.location.replace(`/user/login`);
    } else {
      message.error(msg);
    }
  };

  return (
    <div className="user-info-popver">
      <div className="user-info-finance">
        <div className="user-info-space">
          <span className="user-info-amount">T币余额</span>
          <Button
            className="charge"
            size="small"
            onClick={() => {
              track({ name: '按钮点击', keyword: `个人信息悬浮【充值】` });
              window.open(`${dashboardSitePrefixPath}/dashboard/userCenter/charge`);
            }}
          >
            充值
          </Button>
        </div>
        <div className="money">
          {formatMoney(balance)} <span className="unit">T币</span>
        </div>
      </div>
      <div className="user-info-menu">{menuContent}</div>
      <div className="logout-item" onClick={onLogout}>
        退出登录
      </div>
    </div>
  );
};

const UserInfo = () => {
  const [visible, setVisible] = useState(false);
  const { userInfo } = userModelContainer.useContainer();
  const popContainer = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.userInfoContainer} ref={popContainer}>
      <Popover
        overlayClassName={styles.textin_user_info_popver}
        trigger="hover"
        placement="bottomRight"
        getPopupContainer={() => popContainer.current!}
        // eslint-disable-next-line react/no-unstable-nested-components
        content={() => <PoverContent visible={visible} />}
        afterVisibleChange={(visible) => {
          setVisible(visible);
          if (visible) {
            track({ name: '内容曝光', keyword: `导航栏【个人信息悬浮】` });
          }
        }}
      >
        <div className={styles.userInfoName}>
          <div className="headerUserAvatar" />
          <span style={{ marginLeft: 12 }}>你好，{userInfo.account || '用户名'}</span>
        </div>
      </Popover>
    </div>
  );
};

export default UserInfo;
